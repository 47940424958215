h2{
    text-align: center;
}
.slider-container{
    position: relative;
    overflow-x: hidden;
    transition: .3s ease all;
}
.slider {
    display: flex;

    
}
.item {
    margin: 10px 10px 40px 10px;
    height: 45rem;
    min-width: 40rem;
    max-width: 40rem;
    padding: 1.5rem;
}
.item img{ 
    min-height: 30rem;
    height: 100%;
    width: 100%;
    pointer-events: none;
}
.modo{
    padding: 10px;
    border-radius: 0px 10px 10px 0px;
    color: #fff;
    position: absolute;
    top:100px;
    left:0px;
    background-color:rgba(246, 124, 24, 0.779);

}
.info{
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
.id{
    padding: 10px;
    border-radius: 10px 0px 0px 10px;
    color: #fff;
    position: absolute;
    top:100px;
    right: 0px;
    background-color:rgba(246, 124, 24, 0.779);
}