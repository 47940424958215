@font-face {
	font-family: Poppins;
	src: url(./fonts/Poppins-Regular.ttf);
}



*{
	font-family: 'Poppins';
    margin: 0;
    padding: 0.1rem;
    box-sizing: border-box;
}

/* globales */
a:link, a:visited, a:active {
    text-decoration:none;
    color:rgb(125, 156, 161);
}
#navbar-light {
    width: 100%;
}

li {
    text-decoration: none;
	list-style-type: none;
  }

/* container global */
.inputBuscar{
    width: 100%;
}

.containerInput{
    position: relative;
    align-items: end;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 25px;
}
.lupa {
    position: absolute;
    margin-left: -30px;
    
}

.footer{
	display: flex;
    justify-content:space-around;
    padding: 2rem;
}
.footer img{
   width: 200px;
   padding: 10px;

}
.footer li{
    color:rgb(125, 156, 161);
}
.footer a{
   text-decoration: none;
   color:rgb(125, 156, 161);  
}
.social{
	display: flex;
    flex-direction: column;
	color:rgb(125, 156, 161);
	justify-content: center;
}

.social i{
	font-size: 1.8rem;
	transition: .3s ease;
    color:rgb(125, 156, 161);
}

.social i:hover{
	
}



.detail{
    display:flex;
}

.infoDetail{
   
    display: flex;
    justify-content:space-around;

   
}
.infoDetail .div1{
   max-width: 50%;
}
.infoDetail button{
    color:rgb(125, 156, 161);
    border:0;
    background-color: #f7b553;
}
.infoDetail button:hover{
    
    background-color: #fd9f10;
}    
.containerServices{
    max-width: 90%;
    margin: 0.5rem;
    padding: 1rem;
    display: flex;
    
    border: #f7b553 solid 1px;
    border-radius: .35rem;
    justify-content: space-around;
}   
.services{
    
    
    

}
.location{
  
   margin: 0.5rem;
   position:relative;
   display:flex;
   flex-direction:column;
   min-width:0;
   max-width: 90%;
   word-wrap:break-word;
   background-color:#fff;
   background-clip:border-box;
   border:1px solid #f7b553;
   border-radius:.35rem
    
}  
.servicio{
    display:flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.contenedorServicio{
  
    margin: 0.5rem;
    
    
    min-width:0;
    max-width: 30%;
    background-color:#fff;
    background-clip:border-box;
    border:1px solid #f7b553;
    border-radius:.35rem
} 
.title{
    margin: auto;
}
.btn-color{
    display:inline-block;
    font-weight:400;
    color:#858796;
    text-align:center;
    vertical-align:middle;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    border:1px solid transparent;
    padding:.375rem .75rem;
    font-size:1rem;
    line-height:1.5;
    border-radius:.35rem;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color:#f7b553 ;
}
.btn-color:hover{
    display:inline-block;
    font-weight:400;
    color:#dfe0ea;
    text-align:center;
    vertical-align:middle;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    border:1px solid transparent;
    padding:.375rem .75rem;
    font-size:1rem;
    line-height:1.5;
    border-radius:.35rem;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color:#fe9c0b ;
}
.contenedorAbout{
  
    margin: auto;
    position:relative;
    padding: 2rem;
    min-width:0;
    max-width: 80%;
    background-color:#fff;
    background-clip:border-box;
    border:1px solid #f7b553;
    border-radius:.35rem
}
/* Drag & Drop */

.center-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .file-upload-content {
    display: none;
    text-align: center;
  }

  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }

  .image-upload-wrap {
    position: relative;
    height: 100px;
    border: 4px solid rgb(125, 156, 161);
    margin-left: 10px;
    margin-right: 10px;
  }

  .image-upload-wrap:hover {
    background-color: transparent;
    border: 4px dashed rgb(125, 156, 161);
  }
  .text-information {
    margin-top: 30px;
    text-align: center;
  }

.arrow-right{
    position: absolute;
    display: flex;
    background-color: rgb(255,255,255,0.8);
    border-radius: 50%;
    border: transparent;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3.1rem;
    top:50%;
    width: 2.5rem;
    height: 2.5rem;
    color:rgb(125, 156, 161);
    box-shadow:10px 5px 5px black;
    left:95%;
  }
.arrow-right button{
    border: none;
    background-color: transparent;
    color:rgb(125, 156, 161);
    visibility:visible;
 
   
}
.arrow-right button.activeButton{
    visibility:visible;
}
.arrow-left{
    position: absolute;
    display: flex;
    background-color: rgb(255,255,255,0.8);
    border-radius: 50%;
    border: transparent;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3.1rem;
    top:50%;
    width: 2.5rem;
    height: 2.5rem;
    color:rgb(125, 156, 161);
    box-shadow:10px 5px 5px black;
    right:95%;
    
}

.arrow-left button{
    border: none;
    background-color: transparent;
    color:rgb(125, 156, 161);
    visibility:visible;
}
.arrow-left button.activeButton{
    visibility:visible;
}
.main-container{
    display: flex;
    flex-wrap: wrap;
}

.itemCreate {
    margin: 10px 10px 40px 10px;
    height: 40rem;
    min-width: 40rem;
    max-width: 45rem;
    padding: 1.5rem;
}
.createmode input{
    margin:2px
}
.createmode button{
    margin: 2px;
    border:0;
    background-color: rgba(255,255,255,0.7);;
}
.createmode button.active{

    border:0;
    background-color: #f7b553;
}
.createmode button:hover{
    
    background-color: #f7b553;
}

.confirmar{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;
}
@media screen and (max-width: 480px){
	.footer img{
        visibility: hidden;
        width: 0px;
        padding: 0px;
    }
    .footer ul{
       padding: 0;
    }
    .footer {
        padding: 0.5rem;
        font-size:xx-small;
        /* -ms-text-size-adjust: auto; */
     }
     .infoDetail {
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        flex-wrap: wrap;
    }
    .infoDetail .div1{
        min-width: 300px;
    }
   
    .containerServices{
        flex-direction: column;
    }
    .services{
        flex-direction: column;
       
        /* display:flex;
        justify-content: space-around;
        flex-wrap: wrap;
     */
    }   
    .contenedorServicio{
        max-width: 90%;
    }
    .arrow-right{
        visibility: hidden;
    }
    .arrow-left{
        visibility: hidden;
    }
    .arrow-right button{
        visibility: hidden;
    }
    .arrow-left button{
        visibility: hidden;
    }
}


@media screen and (max-width: 780px){

    .arrow-right{
        visibility: hidden;
    }
    .arrow-left{
        visibility: hidden;
    }
    .arrow-right button{
        visibility: hidden;
    }
    .arrow-left button{
        visibility: hidden;
    }
}

